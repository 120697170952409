<template>
  <transition>
    <div class="full-fixed flex items-center" v-if="Carousel">
      <div class="gallery-wrapper container">
        <BackButton
          v-if="backButton"
          :to="{name: 'CarouselPage', params:{slug: backButton } }"
        />
        <template v-if="Carousel.cards">
          <Gallery
            v-if="Carousel.cards.length < 3 || $root.screen.beforeLg"
            :slides="Carousel.cards"
          >
            <div class="text-center pt-4">
              <Cta
                :href="$root.GenericLabels.maseratiUrl"
                target="_blank"
                rel="noopener"
                data-linktracking="cta:visit-site"
              >
                {{$root.GenericLabels.visitLabel}}
              </Cta>
            </div>
          </Gallery>
          <VerticalGallery
            v-else
            loop
            :initial-index="0"
            :slice-offset="sliceOffsetDesktop"
            :slides="Carousel.cards"
            :slice-length="sliceLenghtDesktop"
            :key="Carousel.carouselKey"
          >
            <Cta
              :href="$root.GenericLabels.maseratiUrl"
              target="_blank"
              rel="noopener"
              data-linktracking="cta:visit-site"
            >
              {{$root.GenericLabels.visitLabel}}
            </Cta>
          </VerticalGallery>
        </template>
      </div>
    </div>
    <Loader v-else />
  </transition>
</template>

<script>
import { Viewport } from '@monogrid/vue-lib'
import GetQueryLanguage from '@/utils/query-language'
import filterByCluster from '@/utils/filter-by-cluster'
import findBack from '@/utils/find-back'
import GetCarousel from '@/graphql/GetCarousel.gql'
import Gallery from '@/organisms/Gallery.vue'
import VerticalGallery from '@/organisms/VerticalGallery.vue'
import Cta from '@/atoms/Cta.vue'
import BackButton from '@/atoms/BackButton.vue'

export default {
  name: 'HomePage',
  mixins: [Viewport],
  components: { BackButton, Cta, Gallery, VerticalGallery },
  props: {
    slug: { type: String }
  },
  apollo: {
    Carousel: {
      query: GetCarousel,
      variables () {
        return {
          slug: this.slug,
          status: 'PUBLISHED',
          variants: GetQueryLanguage(this.$language.current)
        }
      },
      update (data) {
        return data.Carousel
          ? {
              ...data.Carousel,
              carouselKey: this.$language.current,
              cards: filterByCluster(data.Carousel.cards, this.$root.parsedQuery.cluster)
            }
          : data
      },
      result () {
        if (this.Carousel) {
          this.$root.preloader.visible = false
          if (this.Carousel.trackingLabel) {
            this.$root.pushAnalytics('pageload', {
              pageInfo: {
                language: this.$language.current.substr(0, 2),
                pageName: this.Carousel.trackingLabel,
                vehicleName: 'grecale'
              }
            })
          }
        } else {
          this.$router.replace({ name: 'Home' }).catch(() => {})
        }
      }
    }
  },
  computed: {
    backButton () {
      return findBack(this.Carousel)
    },
    sliceLenghtDesktop () {
      const availableHeight = this.viewPort.height * 0.7
      const availableSlots = (availableHeight - 250) / 50

      return Math.max(3, Math.min(this.Carousel.cards.length, ~~availableSlots)) // Math.max(this.Carousel.cards.length, 5)
    },
    sliceOffsetDesktop () {
      console.log(this.sliceLenghtDesktop, Math.max(1, ~~this.sliceLenghtDesktop / 2))
      return Math.max(1, ~~(this.sliceLenghtDesktop / 2) - 1)
    }
  }
}
</script>

<style lang="scss" scoped>
.gallery-wrapper {
  position: relative;

  width: 100%;
}
</style>
