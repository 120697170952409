
<template>
  <div class="row">
    <div class="col-5 col-xl-4 col-2xl-3">
      <button
        type="button"
        class="arrow prev"
        :style="arrowStyle"
        :aria-label="$root.GenericLabels.prevLabel"
        @click="!transitioning && prev()"
      >
        <Arrow />
      </button>
      <nav>
        <transition-group
          tag="ul"
          :name="direction === -1 ? 'slide-from-top' : 'slide-from-bottom'"
          class="gallery py-10"
          @before-leave="transitioning=true"
          @after-leave="transitioning=false"
        >
          <li
            class="item flex items-center"
            v-for="(slide,i) in currentSlice"
            :key="slide.id"
            :class="{
              activeSlide: sliceOffset === i
            }"
            @click="goTo(slide.realIndex, Math.sign(i - sliceOffset ))"
          >
            <h2>{{slide.data.title}}</h2>
          </li>
        </transition-group>
      </nav>
      <button
        type="button"
        class="arrow next"
        :style="arrowStyle"
        :aria-label="$root.GenericLabels.nextLabel"
        @click="!transitioning && next()"
      >
        <Arrow />
      </button>
      <slot />
    </div>
    <div class="col-7 col-xl-8 col-2xl-9">
      <transition :name="direction === -1 ? 'slide-from-top' : 'slide-from-bottom'" mode="out-in" >
        <div
          class="card-wrapper"
          style="width: 100%;"
          :key="currentSlice[sliceOffset].id"
        >
          <Card
            ref="currentCard"
            class="card"
            :tabindex="0"
            v-bind="currentSlice[sliceOffset].data"
          />
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import whatInput from 'what-input'
import gallery from '@/mixins/gallery-mixin'
import Card from '@/molecules/Card.vue'
import Arrow from '@/assets/img/arrow.svg'

export default {
  name: 'VerticalGallery',
  mixins: [gallery],
  components: { Arrow, Card },
  data () {
    return {
      transitioning: false
    }
  },
  mounted () {
    window.addEventListener('keydown', this.onKeyDown)
  },
  beforeDestroy () {
    window.removeEventListener('keydown', this.onKeyDown)
  },
  computed: {
    arrowStyle () {
      return {
        top: `${(115 + 50 * this.sliceOffset) / 16}rem`
      }
    }
  },
  watch: {
    currentIndex () {
      setTimeout(() => {
        const classes = document.documentElement.getAttribute('data-whatclasses')
        if (whatInput.ask() === 'keyboard' && (!classes || classes.indexOf('arrow') < 0)) {
          this.$refs.currentCard.$el.focus()
        }
      }, 400)
    }
  },
  methods: {
    onKeyDown (e) {
      if (e.keyCode === '38' || e.keyCode === 38) {
        // up arrow
        !this.transitioning && this.prev()
      } else if (e.keyCode === '40' || e.keyCode === 40) {
        // down arrow
        !this.transitioning && this.next()
      }
    }
  }
}
</script>

<style lang="scss" scoped>

$transition-duration: 0.3s;

.gallery {
  position: relative;
}

.item {
  width: 100%;
  height: rem(50px);

  cursor: pointer;
}

.activeSlide {
  height: rem(150px);

  cursor: auto;

}

h2 {
  width: 80%;

  transform: scale(0.8);
}

.activeSlide h2 {
  transform: scale(1.2);
}

.card-wrapper {
  position: absolute;
  top: 50% !important;

  margin-top: -30vh;

  &.slide-from-top-leave-to,
  &.slide-from-bottom-enter {
    transform: translate3d(0, 10%, 0);
  }

  &.slide-from-top-enter,
  &.slide-from-bottom-leave-to {
    transform: translate3d(0, -10%, 0);
  }
}

.card {
  height: 60vh;
}

.slide-from-top-leave-active {
  position: absolute;
  bottom: 0;
}

.slide-from-bottom-leave-active {
  position: absolute;
  top: 0;
}

.slide-from-top-move,
.slide-from-bottom-move,
.slide-from-top-enter-active,
.slide-from-top-leave-active,
.slide-from-bottom-enter-active,
.slide-from-bottom-leave-active,
.item,
h2 {
  transform-origin: left center;
  transition-duration: $transition-duration;
  transition-property: transform, height, opacity;
  transition-timing-function: ease;
}

.slide-from-top-leave-to,
.slide-from-bottom-enter {
  opacity: 0;
  transform: translate3d(0, 100%, 0);
}

.slide-from-top-enter,
.slide-from-bottom-leave-to {
  opacity: 0;
  transform: translate3d(0, -100%, 0);
}

.arrow {
  position: absolute;
  z-index: 2;
  top: rem(215px);
  left: 0;

  width: rem(50px);
  height: rem(50px);

  margin-top: rem(-25px);

  transition: opacity 0.3s;

  svg {
    width: 100%;
    height: 100%;
  }

  &:disabled {
    opacity: 0.3;
  }
}

.prev {
  transform: translate(5px, -55px);

  svg {
    transform: rotate(90deg);
  }
}

.next {
  transform: translate(5px, 55px);

  svg {
    transform: rotate(-90deg);
  }
}
</style>
