<template>
<transition>
  <div v-if="PressRoom"  class="page full-fixed">
    <div class="wrapper flex items-center">
      <div class="container relative">
        <BackButton
          v-if="backButton"
          :to="{name: 'CarouselPage', params:{slug: backButton } }"
        />
        <h1 class="mt-4 mb-4">{{PressRoom.title}}</h1>
        <div class="row">
          <div
            v-for="({content, label},i) in PressRoom.contents"
            :key="i"
            class="col-md-6 mb-4"
          >
            <h2 class="mb-4">{{label}}</h2>
            <ul>
              <li class="mb-2" v-for="(item, y) in content" :key="y">
                <a
                  class="flex items-center"
                  :download="item.media.originalName"
                  target="_blank"
                  :href="`${$root.process.env.MEDIA_PATH}/${item.media.id}`"
                >
                  <span>{{ item.label }}</span>
                  <DownloadSvg class="icon"/>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
  <Loader v-else />
</transition>
</template>

<script>
import findBack from '@/utils/find-back'
import GetQueryLanguage from '@/utils/query-language'
import GetPressRoom from '@/graphql/GetPressRoom.gql'
import DownloadSvg from '@/assets/img/download.svg'
import BackButton from '@/atoms/BackButton.vue'

export default {
  name: 'PressRoomPage',
  components: {
    DownloadSvg,
    BackButton
  },
  props: {
    slug: { type: String }
  },
  apollo: {
    PressRoom: {
      query: GetPressRoom,
      variables () {
        return {
          slug: this.slug,
          status: 'PUBLISHED',
          variants: GetQueryLanguage(this.$language.current)
        }
      },
      result () {
        if (this.PressRoom) {
          this.$root.preloader.visible = false
          if (this.PressRoom.trackingLabel) {
            this.$root.pushAnalytics('pageload', {
              pageInfo: {
                language: this.$language.current.substr(0, 2),
                pageName: this.PressRoom.trackingLabel,
                vehicleName: 'grecale'
              }
            })
          }
        } else {
          this.$router.replace({ name: 'Home' }).catch(() => {})
        }
      }
    }
  },
  computed: {
    backButton () {
      return findBack(this.PressRoom)
    }
  }
}
</script>

<style lang="scss" scoped>
.page {
  overflow: auto;

  padding: rem(130px $px-mobile 80px);

  @screen md {
    padding: rem(130px $px-desktop 80px);
  }
}

.wrapper {
  width: 100%;
  min-height: 100%;
}

h2 {
  font-weight: bold;
}

a {
  @screen md {
    font-size: rem(18px);
  }
}

.icon {
  display: inline-block;

  width: rem(16px);
  height: rem(16px);

  margin-left: rem(8px);
}
</style>
