export default {
  data () {
    return {
      showModal: false,
      _initialFocus: null,
      _scrollY: window.scrollY
    }
  },
  methods: {
    _fixBody () {
      document.documentElement.setAttribute(
        'style',
        `position: fixed; top: ${-1 *
          this._scrollY}px; height: 100%; width: 100%`
      )
    },
    _freeBody () {
      document.documentElement.setAttribute('style', 'position: relative;')
      window.scroll(0, this._scrollY)
    },
    _getFocusables () {
      return this.$refs.modalContainer
        ? this.$refs.modalContainer.querySelectorAll(
          'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])'
        )
        : false
    },
    _startFindFocusable () {
      if (!this.$refs.modalContainer || !this._getFocusables()) {
        this.timeout = setTimeout(this._startFindFocusable, 50)
        return
      }
      this.focusableList = this._getFocusables()
      this.focusableList[0].focus()
    },
    openModal (event) {
      this._initialFocus = event.currentTarget
      this._scrollY = window.scrollY
      this.showModal = true
      this._startFindFocusable()
      this._fixBody()
    },
    closeModal () {
      clearTimeout(this.timeout)
      this.focusableList = null
      this.showModal = false
      this._freeBody()
      this._initialFocus.focus()
    },
    checkModalEvent (event) {
      // close modal and return early if escape
      if (event.key === 'Escape') {
        this.showModal = false
        return
      }
      if (!this.focusableList) {
        return
      }

      const focusableList = this._getFocusables()
      // escape early if only 1 or no elements to focus
      if (focusableList.length < 2 && event.key === 'Tab') {
        event.preventDefault()
        focusableList[0] && focusableList[0].focus()
        return
      }
      const last = focusableList.length - 1
      if (
        event.key === 'Tab' &&
        event.shiftKey === false &&
        event.target === focusableList[last]
      ) {
        event.preventDefault()
        focusableList[0].focus()
      } else if (
        event.key === 'Tab' &&
        event.shiftKey === true &&
        event.target === focusableList[0]
      ) {
        event.preventDefault()
        focusableList[last].focus()
      }
    }
  }
}
