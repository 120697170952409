<template>
<transition>
  <div v-if="ExtraContent"  class="page full-fixed">
    <div class="wrapper flex items-center">
      <div class="container relative">
        <BackButton
          v-if="backButton"
          :to="{name: 'CarouselPage', params:{slug: backButton } }"
        />
        <div class="row">
          <div class="col-md-6 flex items-center">
            <div>
              <h1 class="mt-4 mb-4">{{ExtraContent.title}}</h1>
              <MarkdownBlock :text="ExtraContent.description" inline tag="p" class="text mb-8"/>
              <div class="row">
                <div
                  v-for="({label, media},i) in ExtraContent.downloads"
                  :key="i"
                  class="col-md-6 mb-4"
                >
                  <Cta
                    class="download-button text-center"
                    :download="media.originalName"
                    target="_blank"
                    :href="`${$root.process.env.MEDIA_PATH}/${media.id}`"
                  >
                    <span>{{ label }}</span>
                    <template #icon>&nbsp;</template>
                  </Cta>
                </div>
              </div>
            </div>
          </div>

          <div class="col-md-6 mb-8 md:mb-0">
            <ResponsiveImage v-if="ExtraContent.image" :data="ExtraContent.image" contain />
          </div>
        </div>
      </div>
    </div>
  </div>
  <Loader v-else />
</transition>
</template>

<script>
import { MarkdownBlock } from '@monogrid/vue-lib'
import findBack from '@/utils/find-back'
import GetQueryLanguage from '@/utils/query-language'
import GetExtraContent from '@/graphql/GetExtraContent.gql'
import Cta from '@/atoms/Cta.vue'
import ResponsiveImage from '@/atoms/ResponsiveImage.vue'
import BackButton from '@/atoms/BackButton.vue'

export default {
  name: 'ExtraContentPage',
  components: {
    BackButton,
    Cta,
    MarkdownBlock,
    ResponsiveImage
  },
  props: {
    slug: { type: String }
  },
  apollo: {
    ExtraContent: {
      query: GetExtraContent,
      variables () {
        return {
          slug: this.slug,
          status: 'PUBLISHED',
          variants: GetQueryLanguage(this.$language.current)
        }
      },
      result () {
        if (this.ExtraContent) {
          this.$root.preloader.visible = false
          if (this.ExtraContent.trackingLabel) {
            this.$root.pushAnalytics('pageload', {
              pageInfo: {
                language: this.$language.current.substr(0, 2),
                pageName: this.ExtraContent.trackingLabel,
                vehicleName: 'grecale'
              }
            })
          }
        } else {
          this.$router.replace({ name: 'Home' }).catch(() => {})
        }
      }
    }
  },
  computed: {
    backButton () {
      return findBack(this.ExtraContent)
    }
  }
}
</script>

<style lang="scss" scoped>
.page {
  overflow: auto;

  padding: rem(130px $px-mobile 130px);

  @screen md {
    padding: rem(130px $px-desktop 80px);
  }
}

.wrapper {
  width: 100%;
  min-height: 100%;
}

.row {
  @screen beforeMd {
    flex-direction: column-reverse;
  }
}

.download-button:not(.icon) {
  width: 100%;

  justify-content: center;
}

</style>
