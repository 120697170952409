<template>
  <div class="swiper">
    <div ref="prevBtn" class="arrow swiper-button-prev"><Arrow /></div>
    <div ref="nextBtn" class="arrow swiper-button-next"><Arrow /></div>
    <div ref="swiperWrapper" class="swiper-wrapper">
      <Card
        class="swiper-slide"
        v-for="(slide,i) in slides"
        v-bind="slide"
        :key="i"
        :disable-click="dragging"
        :tabindex="activeSlide === i ? 0 : -1"
        @focus.native="goTo(i)"
      />
    </div>
    <slot/>
  </div>
</template>

<script>
import whatInput from 'what-input'
import { Swiper, A11y, Navigation, Keyboard } from 'swiper'
import { gsap } from 'gsap'
import { map, cap } from '@/functions'
import Card from '@/molecules/Card.vue'
import Arrow from '@/assets/img/arrow.svg'

Swiper.use([A11y, Navigation, Keyboard])
const THRESHOLD = 10

export default {
  name: 'Gallery',
  components: { Arrow, Card },
  props: {
    slides: { type: Array, required: true }
  },
  data () {
    return {
      activeSlide: 0,
      dragging: false,
      initialized: false
    }
  },
  activated () {
    this.swiper && this.updateSlider()
  },
  async mounted () {
    await this.$nextTick()
    this.tls = []
    const initialSlide = this.slides.findIndex(slide => {
      if (slide.pressRoom && slide.pressRoom._slug === this.$root.prevRoute.params.slug) {
        return true
      }
      if (slide.carousel && slide.carousel._slug === this.$root.prevRoute.params.slug) {
        return true
      }
      return false
    })
    this.activeSlide = initialSlide
    const settings = this.getBreakpoint()
    this.swiper = new Swiper(this.$el, {
      ...settings,
      speed: 0,
      threshold: THRESHOLD,
      centeredSlides: true,
      virtualTranslate: true,
      watchSlidesProgress: true,
      touchStartPreventDefault: false,
      slideToClickedSlide: false,
      initialSlide: initialSlide > -1 ? initialSlide : 0,
      grabCursor: this.slides.length > 1,
      keyboard: {
        enabled: true
      },
      navigation: {
        nextEl: this.$refs.nextBtn,
        prevEl: this.$refs.prevBtn
      },
      on: {
        init: this.setTranslate,
        setTranslate: this.setTranslate,
        slideChange: this.slideChange,
        touchStart: this.onTouchStart,
        touchEnd: this.onTouchEnd
      }
    })
  },
  beforeDestroy () {
    clearTimeout(this.timeout)
    this.swiper && this.swiper.destroy(true, false)
    if (this.tls.length) {
      this.tls.forEach((tl) => tl.kill())
      this.tls.length = 0
    }
  },
  watch: {
    '$root.screen' () {
      this.updateSlider()
    }
  },
  methods: {
    updateSlider () {
      const settings = this.getBreakpoint()
      for (const key in settings) {
        this.swiper.params[key] = settings[key]
      }
      this.swiper.update()
    },
    getBreakpoint () {
      const settings = {}
      if (this.$root.screen.beforeMd) {
        settings.slidesPerView = 1.2
      } else if (this.$root.screen.beforeXl) {
        settings.slidesPerView = 1.3
      } else {
        settings.slidesPerView = 1.8
      }
      return settings
    },
    onTouchStart () {
      clearTimeout(this.timeout)
      this.dragging = true
    },
    async onTouchEnd (swiper) {
      const { currentX, currentY, startX, startY } = swiper.touches
      const diff = Math.abs(currentX + currentY) - Math.abs(startX + startY)
      if (Math.abs(diff) > THRESHOLD) {
        this.timeout = setTimeout(() => (this.dragging = false), 100)
      } else {
        this.dragging = false
      }
    },
    goTo (index) {
      this.swiper && this.swiper.slideTo(index)
    },
    slideChange (swiper) {
      this.activeSlide = swiper.realIndex
      const classes = document.documentElement.getAttribute('data-whatclasses')
      if (whatInput.ask() === 'keyboard' && (!classes || classes.indexOf('arrow') < 0)) {
        swiper.slides[swiper.realIndex].focus()
      }
    },
    setTranslate (swiper, wrapperTranslate) {
      if (wrapperTranslate === undefined) return
      let duration
      if (this.initialized) {
        duration = cap(
          map(
            Math.abs(wrapperTranslate - swiper.previousTranslate),
            0,
            window.innerWidth,
            0,
            0.75
          ),
          0.2,
          0.75
        )
      } else {
        this.initialized = true
        console.log('initialized')
        duration = 0
      }

      if (this.tls.length) {
        this.tls.forEach((tl) => tl.kill())
        this.tls.length = 0
      }
      this.tls.push(
        gsap.to(this.$refs.swiperWrapper, {
          duration,
          x: -cap(
            -wrapperTranslate,
            swiper.snapGrid[0],
            swiper.snapGrid[swiper.snapGrid.length - 1]
          ),
          ease: 'Power3.out'
        })
      )
      // do magic with each slide
      swiper.slides.forEach((slide, i) => {
        const normProgress = Math.abs(map(cap(slide.progress, -2, 2), -2, 2, -1, 1))
        // console.log(i, 'z-index', normProgress, 2 - normProgress * 2)
        gsap.set(slide, { zIndex: Math.round(2 - normProgress * 2) })
        this.tls.push(gsap.to(slide, {
          duration,
          x: (slide.clientWidth * 0.5) * (normProgress * normProgress) * Math.sign(slide.progress),
          scale: 1 - normProgress * 0.4,
          // blur: 4 * normProgress,
          ease: 'Power3.out'
        }))
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.swiper {
  opacity: 0;
  transition: opacity 0.3s 0.1s;

  &.swiper-container-initialized {
    opacity: 1;
  }
}

.swiper-slide {
  height: 50vh;

  pointer-events: none;

  user-select: none;

  &[tabindex='0'] {
    pointer-events: auto;
  }
}

.arrow {
  position: absolute;
  z-index: 2;
  top: 50%;

  width: rem(50px);
  height: rem(50px);

  transition: opacity 0.3s;

  svg {
    width: 100%;
    height: 100%;
  }

  &.swiper-button-disabled {
    opacity: 0;
    pointer-events: none;
  }

  &.swiper-button-prev {
    @screen beforeMd {
      left: 0;
    }
    @screen md {
      left: 50%;

      transform: translate(-30vw, -50%);
    }
  }

  &.swiper-button-next {
    @screen beforeMd {
      right: 0;
    }
    @screen md {
      right: 50%;

      transform: translate(30vw, -50%);
    }

    svg {
      transform: rotate(180deg);
    }
  }

}
</style>
