<template>
  <component
    :is="componentTag"
    :type="$attrs.to || $attrs.href ? null : $attrs.buttonType || 'button'"
    :target="$attrs.target || null "
    v-bind="$attrs"
    v-on="$listeners"
    class="cta"
    :class="{icon: $attrs.icon}"
  >
    <span class="cta-icon mr-2">
      <BackSvg />
    </span>
    <span class="cta-label uppercase">
      {{ $root.GenericLabels.backLabel}}
    </span>
  </component>
</template>

<script>
import BackSvg from '@/assets/img/back.svg'

export default {
  name: 'Cta',
  inheritAttrs: false,
  components: { BackSvg },
  computed: {
    componentTag () {
      if (this.$attrs.to) {
        return 'router-link'
      } else if (this.$attrs.href) {
        return 'a'
      }
      return 'button'
    }
  }
}
</script>

<style lang="scss" scoped>
.cta {
  position: absolute;
  top: 0;

  display: inline-flex;

  align-items: center;

  appearance: none !important;
  color: $c-white;
  transform: translateY(-110%);

  &:disabled {
    opacity: 0.4;
    pointer-events: none;
  }
}

.cta-label,
.cta-icon {
  position: relative;
  z-index: 1;
}

.cta-label {
  text-decoration: underline;
}

.cta-icon {
  ::v-deep svg {
    width: 1.3em;
    height: 1.3em;
  }
}

</style>
