<template>
  <transition>
    <div class="full-fixed flex items-center justify-center" v-if="PostEvent">
      <LogoAndContent :title="PostEvent.title">
        <Cta
          class="inline-block"
          :to="{ name: 'CarouselPage', params: { slug: PostEvent.carousel._slug }}"
          data-linktracking="cta:discover-more"
        >
          {{PostEvent.ctaLabel}}
        </Cta>
      </LogoAndContent>
    </div>
    <Loader v-else />
  </transition>
</template>

<script>
import GetQueryLanguage from '@/utils/query-language'
import GetPostEvent from '@/graphql/GetPostEvent.gql'
import Cta from '@/atoms/Cta.vue'
import LogoAndContent from '@/molecules/LogoAndContent.vue'

export default {
  name: 'HomePage',
  components: { Cta, LogoAndContent },
  apollo: {
    PostEvent: {
      query: GetPostEvent,
      variables () {
        return {
          status: 'PUBLISHED',
          variants: GetQueryLanguage(this.$language.current)
        }
      },
      result () {
        if (this.PostEvent) {
          this.$root.preloader.visible = false
          this.$root.pushAnalytics('pageload', {
            pageInfo: {
              language: this.$language.current.substr(0, 2),
              pageName: 'grecale:post-event',
              vehicleName: 'grecale'
            }
          })
          if (this.$root.prevState === 'live') {
            this.$root.prevState = 'post'
            this.$router.push({ name: 'CarouselPage', params: { slug: this.PostEvent.carousel._slug } })
          }
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
