function modulate (index, length) {
  return (index + length * 5) % length
}
export default {
  props: {
    slides: { type: Array, required: true },
    loop: { type: Boolean },
    initialIndex: { type: Number, default: 0 },
    sliceLength: { type: Number, default: 1 },
    sliceOffset: { type: Number, default: 0 }
  },
  data () {
    return {
      currentSlice: this.getCurrentSlice(),
      direction: 1,
      lastDiff: 0,
      currentIndex: this.initialIndex
    }
  },
  watch: {
    sliceLength () {
      this.currentSlice.splice(0, this.currentSlice.length)
      this.getCurrentSlice().forEach(item => this.currentSlice.push(item))
    },
    currentIndex: {
      immediate: true,
      handler (currentIndex, oldIndex = 0) {
        if (currentIndex === oldIndex) return
        const sideIndex = this.direction < 0 ? this.currentSlice[0].realIndex : this.currentSlice[this.sliceLength - 1].realIndex
        for (let i = 1; i <= Math.abs(this.lastDiff); i++) {
          const indexToAdd = modulate(sideIndex + this.direction * i, this.slides.length)
          const newSlide = {
            id: Math.random() + Date.now() + i,
            data: this.slides[indexToAdd],
            realIndex: indexToAdd
          }
          if (this.direction < 0) {
            this.currentSlice.unshift(newSlide)
            this.currentSlice.pop()
          } else {
            this.currentSlice.push(newSlide)
            this.currentSlice.shift()
          }
        }
      }
    }
  },
  methods: {
    getCurrentSlice () {
      const sliceStart = this.initialIndex - this.sliceOffset
      const currentSlice = []
      for (let i = 0; i < this.sliceLength; i++) {
        const index = modulate(sliceStart + i, this.slides.length)
        currentSlice.push({
          id: Math.random() + Date.now(),
          data: this.slides[index],
          realIndex: index
        })
      }
      return currentSlice
    },
    goTo (index, forceDirection) {
      let diff = modulate(Math.abs(index - this.currentIndex), this.slides.length)
      if (diff === this.slides.length - 1) {
        diff = 1
      }
      if (diff === 0) return
      this.direction = forceDirection !== undefined ? forceDirection : index < this.currentIndex ? -1 : 1
      this.lastDiff = this.direction * diff
      this.currentIndex = modulate(this.currentIndex + this.lastDiff, this.slides.length)
      this.$emit('change', this.currentIndex)
    },
    next () {
      const newIndex = this.currentIndex + 1
      if (!this.loop && newIndex >= this.slides.length) return
      this.goTo(newIndex)
    },
    prev () {
      const newIndex = this.currentIndex - 1
      if (!this.loop && newIndex < 0) return
      this.goTo(this.currentIndex - 1)
    }
  }
}
