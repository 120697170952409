<template>
  <component
    class="card"
    draggable="false"
    v-bind="cardOptions"
    :is="cardComponent"
    :tabindex="tabindex"
    :class="{loaded: imageLoaded}"
    :data-linktracking="trackingLabel"
    @click="onClick"
  >
    <!-- :type="cardComponent === 'button' ? 'button' : null" -->
    <ResponsiveImage v-if="image" cover :data="image" @load="imageLoaded = true"/>
    <MarkdownBlock :text="label || title" inline tag="h2" class="text"/>
    <portal to="modal">
      <div
        v-if="showModal"
        :key="id"
        ref="modalContainer"
        class="modal full-fixed"
        role="dialog"
        :aria-modal="showModal"
        :aria-hidden="showModal ? 'false' : 'true'"
        @keydown="checkModalEvent"
      >
        <button
          v-focus
          type="button"
          class="close-modal"
          :aria-label="$root.GenericLabels.closeLabel"
          @click="closeModal"
        >
          <CloseSvg />
          <span>Close</span>
        </button>
        <ThronPlayer
          :id="videoSessId"
          :key="videoXContentId+videoSessId"
          :config="{
            clientId: videoClientId,
            xcontentId: videoXContentId,
            sessId: videoSessId,
            autoplay: true
          }"
        />
      </div>
    </portal>
  </component>
</template>

<script>
import { MarkdownBlock } from '@monogrid/vue-lib'
import ThronPlayer from '@/organisms/ThronPlayer'
import ModalMixin from '@/mixins/modal-mixin'
import ResponsiveImage from '@/atoms/ResponsiveImage.vue'
import CloseSvg from '@/assets/img/close.svg'

export default {
  name: 'Card',
  mixins: [ModalMixin],
  inheritAttrs: false,
  props: {
    id: { type: String },
    title: { type: String },
    label: { type: String },
    image: { type: Object },
    pressRoom: { type: Object },
    extraContent: { type: Object },
    carousel: { type: Object },
    videoClientId: { type: String },
    videoXContentId: { type: String },
    videoSessId: { type: String },
    trackingLabel: { type: String },
    disableClick: { type: Boolean, default: false },
    tabindex: { type: Number }
  },
  components: { CloseSvg, MarkdownBlock, ResponsiveImage, ThronPlayer },
  directives: {
    focus: {
      // directive definition
      inserted: function (el) {
        el.focus()
      }
    }
  },
  data () {
    return {
      imageLoaded: false
    }
  },
  computed: {
    cardComponent () {
      if (this.pressRoom || this.carousel || this.extraContent) {
        return 'router-link'
      }
      return 'button'
    },
    cardOptions () {
      if (this.pressRoom) {
        return {
          to: {
            name: 'PressRoom',
            params: {
              slug: this.pressRoom._slug
            }
          }
        }
      }
      if (this.extraContent) {
        return {
          to: {
            name: 'ExtraContent',
            params: {
              slug: this.extraContent._slug
            }
          }
        }
      }
      if (this.carousel) {
        return {
          to: {
            name: 'CarouselPage',
            params: {
              slug: this.carousel._slug
            }
          }
        }
      }
      return { type: 'button' }
    }
  },
  methods: {
    onClick (e) {
      if (this.disableClick) {
        e.preventDefault()
        e.stopPropagation()
        return
      }
      this.cardComponent === 'button' && this.openModal(e)
    }
  }
}
</script>

<style lang="scss" scoped>
.card {
  position: relative;

  display: block;
  overflow: hidden;

  width: 100%;

  border-radius: rem(20px);

  background-color: $c-blue;

  text-align: right;
  transition: background-color 0.2s 0.1s;

  img {
    position: relative;
    z-index: 1;
  }

  &::before {
    position: absolute;
    z-index: 0;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    animation: shimmer 2s infinite;
    background-image: linear-gradient(90deg,
    rgba(#043061, 0) 0,
    rgba(#043061, 0.2) 20%,
    rgba(#043061, 0.5) 60%,
    rgba(#043061, 0));
    content: '';
    transform: translateX(-100%);

    transition: opacity 0.2s, visibility 0 0.2;
  }

  &.loaded {
    background-color: transparent;

    &::before {
      animation-play-state: paused;
      opacity: 0;
      visibility: hidden;
    }
  }

}

@keyframes shimmer {
  100% {
    transform: translateX(100%);
  }
}

.text {
  position: absolute;
  z-index: 2;
  right: 0;
  bottom: 0;

  padding: rem(8px 8px 8px 30px);

  &::before {
    position: absolute;
    z-index: -1;
    top: 0;
    right: 0;
    bottom: -2px;
    left: 0;

    background: $c-black;

    clip-path: polygon(rem(25px) 0%, 100% 0, 100% 100%, 0% 100%);
    content: '';
  }
}
</style>
