
/**
 * Maps a value between two ranges
 * @param  {Number} n       Value to map
 * @param  {Number} min1    Source range minimum
 * @param  {Number} max1    Source range maximum
 * @param  {Number} min2    Target range minimum
 * @param  {Number} max2    Target range maximum
 * @return {Number}         Mapped value
 */
export function map (n, min1, max1, min2, max2) {
  return ((n - min1) * (max2 - min2)) / (max1 - min1) + min2
}

/**
 * Limit a value between a min and a max (inclusive)
 * @param  {Number} n   Value to cap
 * @param  {Number} min Minimum possible value
 * @param  {Number} max Maximum possible value
 * @return {Number}     Capped value
 */
export function cap (n, min, max) {
  return Math.min(Math.max(n, min), max)
}
